import { createInertiaApp } from "@inertiajs/vue3";
import "vite/modulepreload-polyfill";
import { createApp, type DefineComponent, h } from "vue";

import "./styles/index.scss";

async function initializeApp() {
  await createInertiaApp({
    progress: {
      color: "#EC3FDA",
    },
    resolve: (name) => {
      const pages = import.meta.glob<DefineComponent>(["@/pages/**/*.vue"]);

      const component = pages[`/website/frontend/pages/${name}.vue`];

      return component();
    },
    setup({ el, App, props, plugin }) {
      createApp({ render: () => h(App, props) })
        .use(plugin)
        .mount(el);
    },
  });
}

initializeApp().catch((e) => {
  console.error("Failed to initialize app:", e);
});
